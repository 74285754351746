<template>
	<div class="section-12 sect-mobile">
		<div class="container-section-mobile">
			<div class="webinar">Webinar Gratuit</div>
			<div class="title">{{ last_webinar.title }}</div>
			<div class="desc" v-html="last_webinar.description"></div>
			<a :href="last_webinar.url" target="_blank" class="save-seat">
				Rezervă-ți locul gratuit
				<icon-arrow class="icon-arrow"/>
			</a>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../../assets/landing/Arrow'
	import { gsap } from "gsap";
	import axios from 'axios';
	export default {
		data() {
			return {
				last_webinar: {}
			};
		},
		components: {
			IconArrow,
		},
		mounted(){
			this.getLastWebinar();
			gsap.to('.actions-fixed', {
				scrollTrigger:{
					trigger: '.section-12',
					start: "top 5%",
					end: "bottom 5%",
					onToggle: self5 => self5.isActive ? document.querySelector('.actions-fixed').classList.add('active-top-12') : document.querySelector('.actions-fixed').classList.remove('active-top-12'),
					scrub: true
				},
			})

			gsap.to('.actions-fixed', {
				scrollTrigger:{
					trigger: '.section-12',
					start: "top 95%",
					end: "bottom 95%",
					onToggle: self6 => self6.isActive ? document.querySelector('.actions-fixed').classList.add('active-bottom-12') : document.querySelector('.actions-fixed').classList.remove('active-bottom-12'),
					scrub: true
				}
			})
		},
		methods: {
			async getLastWebinar() {
				axios.get("https://backend.tbf.ro/api/webinars").then(res => {
					this.last_webinar = res.data.data[0];
				})
			},			
		}
	}
</script>